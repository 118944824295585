
import { MoDate } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISentenceItem } from '../type';

@Component({
    components: {},
})
export default class NewsPoster extends Vue {
    @Prop() newsItemList!: ISentenceItem[];
    @Prop() formData!: Iobject;
    @Prop() dateArr!: string[];

    get dateStr(): string {
        const o = MoDate.getTimeQuota(new Date(this.formData.articleName));

        return [
            MoDate.format2('YYYY年M月D日，', o),
            `${this.formData.lunar ? `农历${this.formData.lunar}，` : ''} ${MoDate.format2('周W', o)}，`,
            '每日一分钟知天下大事',
        ].join('');
    }
}
